<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <div class="button_list">
                    <sn-button :snButton="snButton" @handleChange="handleChange"></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form ref="formdata" :inline="true" :model="formdata.saveJson" class="demo-form-inline form_row" size="small" label-width="200px">
                <el-form-item label="站点编号" prop="STATIONNUM">
                    <el-input v-model="formdata.saveJson.STATIONNUM" :maxlength="50" placeholder="请输入站点编号" :disabled="!isEdit"></el-input>
                </el-form-item>
                <el-form-item label="站点名称" prop="STATIONNAME">
                    <el-input v-model="formdata.saveJson.STATIONNAME" :maxlength="50" placeholder="请输入站点名称" :disabled="!isEdit"></el-input>
                </el-form-item>
                <el-form-item label="发布时间" prop="JCSJ">
                    <el-date-picker v-model="formdata.saveJson.JCSJ" :disabled="!isEdit" value-format="yyyy-mm-dd" placeholder="请选择发布时间"></el-date-picker>
                </el-form-item>
                <el-form-item label="当前气温" prop="TEMP">
                    <el-input v-model="formdata.saveJson.TEMP" :maxlength="50" onkeydown="if(event.keyCode==32) return false" placeholder="请输入当前气温" :disabled="!isEdit">
                        <template slot="append">°C</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="最低气温" prop="TEMP_MIN">
                    <el-input v-model="formdata.saveJson.TEMP_MIN" :maxlength="50" onkeydown="if(event.keyCode==32) return false" placeholder="请输入最低气温" :disabled="!isEdit">
                        <template slot="append">℃</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="最高气温" prop="TEMP_MAX">
                    <el-input v-model="formdata.saveJson.TEMP_MAX" :maxlength="50" onkeydown="if(event.keyCode==32) return false" placeholder="请输入最高气温" :disabled="!isEdit">
                        <template slot="append">℃</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="1小时内的降雨量" prop="RAIN">
                    <el-input v-model="formdata.saveJson.RAIN" :maxlength="50" onkeydown="if(event.keyCode==32) return false" placeholder="请输入1小时内的降雨量" :disabled="!isEdit">
                        <template slot="append">mm</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="3小时降雨量" prop="RAIN_3">
                    <el-input v-model="formdata.saveJson.RAIN_3" :maxlength="50" onkeydown="if(event.keyCode==32) return false" placeholder="请输入3小时降雨量" :disabled="!isEdit">
                        <template slot="append">mm</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="6小时降雨量" prop="RAIN_6">
                    <el-input v-model="formdata.saveJson.RAIN_6" :maxlength="50" onkeydown="if(event.keyCode==32) return false" placeholder="请输入6小时降雨量" :disabled="!isEdit">
                        <template slot="append">mm</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="12小时降雨量" prop="RAIN_12">
                    <el-input v-model="formdata.saveJson.RAIN_12" :maxlength="50" onkeydown="if(event.keyCode==32) return false" placeholder="请输入12小时降雨量" :disabled="!isEdit">
                        <template slot="append">mm</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="24小时降雨量" prop="RAIN_24">
                    <el-input v-model="formdata.saveJson.RAIN_24" :maxlength="50" onkeydown="if(event.keyCode==32) return false" placeholder="请输入24小时降雨量" :disabled="!isEdit">
                        <template slot="append">mm</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="平均风速" prop="WIND_SPEED">
                    <el-input v-model="formdata.saveJson.WIND_SPEED" :maxlength="50" onkeydown="if(event.keyCode==32) return false" placeholder="请输入平均风速" :disabled="!isEdit">
                        <template slot="append">m/s</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="极大风速" prop="WIND_JDSPEED">
                    <el-input v-model="formdata.saveJson.WIND_JDSPEED" :maxlength="50" onkeydown="if(event.keyCode==32) return false" placeholder="请输入极大风速" :disabled="!isEdit">
                        <template slot="append">m/s</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="24小时极大风速" prop="WIND_JDSPEED_MAX24">
                    <el-input v-model="formdata.saveJson.WIND_JDSPEED_MAX24" :maxlength="50" onkeydown="if(event.keyCode==32) return false" placeholder="请输入24小时极大风速" :disabled="!isEdit">
                        <template slot="append">m/s</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="气压" prop="PRESSURE">
                    <el-input v-model="formdata.saveJson.PRESSURE" :maxlength="50" onkeydown="if(event.keyCode==32) return false" placeholder="请输入气压" :disabled="!isEdit">
                        <template slot="append">hPa</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="能见度" prop="VISIBILITY">
                    <el-input v-model="formdata.saveJson.VISIBILITY" :maxlength="50" onkeydown="if(event.keyCode==32) return false" placeholder="请输入能见度" :disabled="!isEdit">
                        <template slot="append">m</template>
                    </el-input>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import snButton from '../../../edit-button.js';
import infoMixin from '../../../info_mixin.js';
import { mapActions } from 'vuex';
export default {
    name: "servicedata_qx_form",
    components: {
    },
    mixins: [infoMixin],
    data() {
        return {
            notNeedFile: true,
            snButton: snButton,
            // 根据承诺事项来源类型不同掉服务获取承诺事项来源列表
            cnwcsj: false,
            cnwcsj_date: '',
            formdata: {
                saveJson: {
                    STATIONNUM: '',
                    STATIONNAME: '',
                    JCSJ: '',
                    TEMP: 0,
                    TEMP_MIN: 0,
                    TEMP_MAX: 0,
                    RAIN: 0,
                    RAIN_3: 0,
                    RAIN_6: 0,
                    RAIN_12: 0,
                    RAIN_24: 0,
                    WIND_SPEED: 0,
                    WIND_JDSPEED: 0,
                    WIND_JDSPEED_MAX24: 0,
                    PRESSURE: 0,
                    VISIBILITY: 0,
                },
                heritageId: '',
                itemID: '',
                userName: '',
                userId: ''
            }
        };
    },
    computed: {
    },
    watch: {
        'formdata.saveJson.cnsxlylx': function() {
        },
    },
    mounted() {
    },
    methods: {
        ...mapActions([
        ]),
    }
};
</script>
<style lang="scss" scoped>
.basic_data_edit {
    // border:1px solid red;
}
</style>
